<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--操作栏-->
    <refreshbtn @refesh="refesh">
      <template slot="search-btns">
        <el-button type="success" size="small" @click="addFromMethod" v-has="'/api/tag/cate/create'">添加</el-button>
      </template>
    </refreshbtn>
    <!--数据表格-->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column
        prop="id"
        label="Id"
        align="center"
        width="140"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="cat_name"
        label="分类名称"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.cat_name || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="权重" align="center" width="120" prop="weight">
      </el-table-column>
      <el-table-column
        label="排序值"
        prop="sort_order"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column label="是否多选" align="center" width="80">
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.is_multiple"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="enable"
        label="是否启用"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.enable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="size" label="up主展示" align="center" width="100">
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.is_show"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="isFold"
        label="是否折叠"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.isFold"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-has="'/api/tag/cate/update'" @click="editForm(scope.row)">
            编辑
          </el-button>
          <el-button
            v-has="'/api/tag/cate/destroy'"
            type="text"
            size="small"
            @click="destroyCate(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addForm ref="addForm"></addForm>
  </div>
</template>

<script>
import addForm from "./components/addForm.vue";
import API from "@/api";
export default {
  data() {
    return {
      // 请求列表查询
      params: {
        page: 1,
        size: 10,
      },
      loading: false,
      // 列表数据
      tableData: [],
      total: "",
      dialogVisible: false,
      updataSta: false,
      isRefesh:true,
    };
  },
  components: {
    addForm,
  },
  created() {
    let listRule = '/api/tag/cate/list'
    let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
    if(obj[listRule]){
      this.isRefesh = true
      this.getList()
    }else{
      this.isRefesh = false 
    }
  },
  methods: {
    refesh(){
      this.isRefesh?this.getList():''
    },
    async updateData(row) {
      await API.updateCate(row);
    },
    destroyCate(id) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.destroyCate({ id }).then((res) => {
          if(!res.code){
            this.$message.success("删除成功");
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        });
        
      });
    },
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    editForm(data) {
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    // 获取视频列表
    getList() {
      this.loading = true
      API.getCateList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.total = res.data.pagination.count;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.tableData = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}
.bottom {
  margin-top: 20px;
  .btn {
    display: inline-block;
    margin-left: 20px;
  }
  .page {
    display: inline-block;
    float: right;
  }
}
.el-pagination {
  margin-top: 0 !important;
}
.dialog-footer {
  left: 0;
}
.tagBox {
  display: inline-block;
  margin-right: 5px;
}
</style>
